<template>
	<div class="header_container">
		<!--<el-scrollbar style="margin-right: 6px;" wrap-style="overflow-x:hidden;">-->
			<div class="head_box">
				<div class="header_tags">
					<el-tag v-for="tag in tags" :key="tag.title" :closable="!tag.closeFlag" size="large" @close="handleClose(tag)"
									:type="tag.type">
						<router-link :to="tag.router" class="tag-title">{{ tag.name }}</router-link>
					</el-tag>
				</div>
				<div class="head_right">
					<div class="head_right_user">
						<el-dropdown trigger="click">
							<span class="el-dropdown-link">
								 <el-avatar :size="40" :src="avatar"  fit="contain"></el-avatar>
							</span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item @click.native="outLogin">退出登录</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
						<span style="font-weight: bold;">你好，{{this.user.userName}}！</span>
					</div>
				</div>
			</div>
		<!--</el-scrollbar>-->
	</div>
</template>

<script>

export default {
	name: "Header",
	data() {
		return {
			tags: [],
			avatar: require('../../assets/images/logo.jpg'),
			user:{}
		};
	},
	//监听路由变化
	watch: {
		$route() {
			this.setTags(this.$route);
		}
	},
	mounted() {
		this.user = JSON.parse(localStorage.getItem('user'));
		this.setTags(this.$route);
	},
	methods: {
		//设置标签
		setTags(route) {
			//保证首页存在
			let isHome = this.tags.some(item => {
				return item.router === '/home';
			});
			if (!isHome) {
				this.tags.push({
					title: '首页',
					name: '首页',
					router: '/home',
					closeFlag: true
				})
			}
			let isExit = this.tags.some(item => {
				return item.router === route.fullPath;
			});

			//判断是否是content,如果是给添加宝宝页面标签

			let isBaby = this.tags.some(item => {
				return item.router === '/baby';
			});

			if (isBaby === false && route.fullPath === '/content') {
				this.tags.push({
					title: '我的宝宝',
					name: '我的宝宝',
					router: '/baby'
				})
				return;
			}


			if (isExit === false && route.fullPath !== '/content') {
				//判断是否存在首页
				if (route.fullPath === '/home') {
					return;
				}

				this.tags.push({
					title: route.name, //标签名
					name: route.meta.title, //路由里的name对应vue页的name,标签列表里的name可以做vue页面缓存
					router: route.fullPath, //路由
					closeFlag: false //是否可关闭
				});
				// this.$store.dispatch('tagsView/addView', this.$route)
			}
		},
		//点击关闭tag
		handleClose(tag) {
			this.tags.splice(this.tags.indexOf(tag), 1);
			// 关闭tag跳转到最后一个tag页面
			if (this.tags.length > 0) {
				this.$router.push(this.tags[this.tags.length - 1].router);
			}
		},
		// 退出登录
		outLogin(){
			localStorage.removeItem('user');
			localStorage.removeItem('Authorization');
			this.$router.push('/login')
		}
	}
}
</script>

<style scoped lang="scss">

/* 解决自定义滚动条 x 轴显示问题 */


.header_tags {
	display: flex;
	align-items: center;
	//margin-top: 10px;

	.el-tag {
		margin-left: 6px;
	}
}

.tag-title {
	text-decoration: none;
}

.head_right_feature{
	display: flex;
}

.head_box{
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.head_right{
	display: flex;
	align-items: center;
	margin-right: 40px;
}

</style>
